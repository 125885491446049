import React, { useState, useEffect } from "react";

import moment from "moment";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import formatNumber from "format-number";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  LinearProgress,
  ListSubheader,
  ButtonGroup,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  CircularProgress,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Skeleton, Alert } from "@material-ui/lab";
import PreviousIcon from "@material-ui/icons/ArrowBack";
import NextIcon from "@material-ui/icons/ArrowForward";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { BaseAPI } from "../../data/BaseAPI";
import { useProfile } from "../../data/Profile";
import { Customer, Dealer } from "../../types";
import localization from "../../utils/localizations";
import StateSelect from "../forms/fields/StateSelect";
import SortButton from "./components/SortButton";
import HinField from "../forms/fields/HinField";
import WarrantyClaimForm from "../forms/WarrantyClaimForm";

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    paddingBottom: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  tableNav: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  appBar: {
    position: "relative",
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paginationControl: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FindCustomerFormValues {
  serial_number: string;
  email: string;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  dealer: string | undefined;
  ordering?: string | undefined;
  search: string;
}

export default function CustomerCollection(props: { className?: string }) {
  const classes = useStyles();

  const [filter, setFilter] = useState<FindCustomerFormValues | undefined>(
    undefined,
  );
  const [pageSize, setPageSize] = useState(50);
  const [allDealers, setAllDealers] = useState(
    ((window as any)._cached && (window as any)._cached["all_dealers"]
      ? (window as any)._cached["all_dealers"]
      : []) as Dealer[],
  );
  const [selectedDealer, setSelectedDealer] = useState(
    null as Dealer | null | undefined,
  );
  const [profile, profileLoading, updateProfile, updateProfilePicture] =
    useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [customers, setCustomers] = useState([] as Customer[]);
  const [customersMeta, setCustomersMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0,
  });

  const { path, url } = useRouteMatch();
  const backUrl = `${url}../`;

  const loadCustomers = async (
    page?: number,
    dealer?: Dealer | null | undefined,
    filter?: FindCustomerFormValues,
  ) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = `warranty-registrations/`;
    let kwargs: any = {
      page_size: pageSize,
    };
    if (page) kwargs.page = page;
    if (dealer) kwargs.dealer = dealer.id;
    if (filter?.serial_number) {
      let serialNumber = filter.serial_number;
      if (serialNumber && serialNumber.startsWith("US")) {
        serialNumber = serialNumber.substring(2);
      }
      if (serialNumber) {
        kwargs.serial_number = serialNumber;
      }
    }
    if (filter?.email) kwargs.email = filter.email;
    if (filter?.first_name) kwargs.first_name = filter.first_name;
    if (filter?.last_name) kwargs.last_name = filter.last_name;
    if (filter?.city) kwargs.city = filter.city;
    if (filter?.state) kwargs.state = filter.state;
    if (filter?.search) kwargs.search = filter.search;
    if (filter?.ordering) kwargs.ordering = filter.ordering;
    else if (sortKey) kwargs.ordering = sortKey;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && page > customersMeta.page) {
          const results = data.results as Customer[];
          setCustomers(results);
        } else {
          setCustomers(data.results as Customer[]);
        }

        setIsLoading(false);
        setCustomersMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next,
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const downloadCustomers = async (
    page?: number,
    filter?: FindCustomerFormValues,
  ) => {
    if (isLoading) return;

    const api = new BaseAPI();
    let url = `${api.baseUrl}warranty-customers-download/`;

    let kwargs: any = {
      page_size: pageSize,
    };
    if (page) kwargs.page = page;
    if (filter?.dealer) kwargs.dealer = filter.dealer;
    if (filter?.serial_number) {
      let serialNumber = filter.serial_number;
      if (serialNumber && serialNumber.startsWith("US")) {
        serialNumber = serialNumber.substring(2);
      }
      if (serialNumber) {
        kwargs.serial_number = serialNumber;
      }
    }
    if (filter?.email) kwargs.email = filter.email;
    if (filter?.first_name) kwargs.first_name = filter.first_name;
    if (filter?.last_name) kwargs.last_name = filter.last_name;
    if (filter?.city) kwargs.city = filter.city;
    if (filter?.state) kwargs.state = filter.state;
    if (filter?.search) kwargs.search = filter.search;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    window.open(url, "_blank");
  };

  useEffect(() => {
    loadCustomers();
  }, []);

  const loadAllDealers = async () => {
    const api = new BaseAPI();
    try {
      const data = await api.get("all-dealers/");
      if (data instanceof Array) {
        setAllDealers(data as Dealer[]);

        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached["all_dealers"])
          (window as any)._cached["all_dealers"] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (values: FindCustomerFormValues) => {
    setIsLoading(true);
    setFilter({
      serial_number: values.serial_number
        ? values.serial_number.toUpperCase()
        : values.serial_number,
      email: values.email,
      dealer: values.dealer,
      first_name: values.first_name,
      last_name: values.last_name,
      city: values.city,
      state: values.state,
      search: values.search,
    });
    const api = new BaseAPI();
    await loadCustomers(undefined, selectedDealer, values);
    setIsLoading(false);
  };

  const onValidate = (values: FindCustomerFormValues) => {
    const errors: any = {};

    return errors;
  };

  useEffect(() => {
    loadCustomers(
      undefined,
      selectedDealer,
      Object.assign({ ordering: sortKey }, filter),
    );
  }, [sortKey, pageSize]);

  useEffect(() => {
    if (allDealers.length === 0) loadAllDealers();
  }, []);

  let paginations = Array.from(
    { length: customersMeta.num_pages },
    (_, i) => i + 1,
  );
  if (paginations.length > 10) {
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(
      paginations.length - 4,
      paginations.length - 1,
    );
    let middles: number[] = [];
    if (starts.length && ends.length && customersMeta.page > 1) {
      if (
        !starts.includes(customersMeta.page - 1) &&
        !ends.includes(customersMeta.page - 1)
      )
        middles.push(customersMeta.page - 1);
      if (
        !starts.includes(customersMeta.page) &&
        !ends.includes(customersMeta.page)
      )
        middles.push(customersMeta.page);
      if (
        !starts.includes(customersMeta.page + 1) &&
        !ends.includes(customersMeta.page + 1)
      )
        middles.push(customersMeta.page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }
  return (
    <div className={classes.root}>
      <Paper style={{ paddingTop: 12 }}>
        <Form
          onSubmit={onSubmit}
          validate={onValidate}
          initialValues={{
            serial_number: "",
            email: "",
            first_name: "",
            last_name: "",
            city: "",
            state: "",
            dealer: undefined,
            search: "",
          }}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            errors,
          }) => (
            <form
              onSubmit={handleSubmit}
              className={classes.root}
              style={{ margin: 12, paddingBottom: 12 }}
            >
              <Grid container>
                <Grid container spacing={3}>
                  <Field name="search">
                    {(props) => (
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <TextField
                            id={props.input.name}
                            label={"Search"}
                            placeholder="Enter keywords to search..."
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                            helperText={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? props.meta.error || props.meta.submitError
                                : undefined
                            }
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                </Grid>
                <Grid container spacing={3}>
                  <Field name="first_name">
                    {(props) => (
                      <Grid item xs={3}>
                        <FormControl
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <TextField
                            id={props.input.name}
                            label={"First Name"}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                            helperText={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? props.meta.error || props.meta.submitError
                                : undefined
                            }
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                  <Field name="last_name">
                    {(props) => (
                      <Grid item xs={3}>
                        <FormControl
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <TextField
                            id={props.input.name}
                            label={"Last Name"}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                            helperText={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? props.meta.error || props.meta.submitError
                                : undefined
                            }
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                  <Field name="city">
                    {(props) => (
                      <Grid item xs={3}>
                        <FormControl
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <TextField
                            id={props.input.name}
                            label={"City"}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                            helperText={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? props.meta.error || props.meta.submitError
                                : undefined
                            }
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                  <Field name="state">
                    {(props) => (
                      <Grid item xs={3}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <InputLabel>State</InputLabel>
                          <StateSelect
                            id={props.input.name}
                            label={"State"}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value.substring(0, 150)}
                            onChange={props.input.onChange}
                            optional
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                          />
                          {(props.meta.error || props.meta.submitError) &&
                            props.meta.touched && (
                              <Typography style={{ color: "red" }}>
                                {props.meta.error}
                              </Typography>
                            )}
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                </Grid>
                <Grid container spacing={3}>
                  <Field name="dealer">
                    {(props) => (
                      <Grid item xs={3}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <InputLabel>Dealer</InputLabel>
                          <Select
                            id={props.input.name}
                            label={"Dealer"}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={(event) => {
                              props.input.onChange(event);
                              if (!event.target.value) {
                                setSelectedDealer(undefined);
                                return;
                              }

                              allDealers.filter((dealer) => {
                                if (dealer.id == event.target.value) {
                                  setSelectedDealer(dealer);
                                }
                              });
                            }}
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                          >
                            <ListSubheader>Active Dealers</ListSubheader>
                            <MenuItem value="">&mdash;</MenuItem>
                            {allDealers
                              .filter((dealer) => dealer.active)
                              .map((dealer, i) => (
                                <MenuItem
                                  key={`dealer-menu-item-${i}`}
                                  value={dealer.id}
                                >
                                  {dealer.customer_name}{" "}
                                  {dealer.customer_number
                                    ? `(${dealer.customer_number.padStart(5, "0")})`
                                    : ""}
                                </MenuItem>
                              ))}
                            <ListSubheader>Inactive Dealers</ListSubheader>
                            {allDealers
                              .filter((dealer) => !dealer.active)
                              .map((dealer, i) => (
                                <MenuItem
                                  key={`dealer-menu-item-inactive-${i}`}
                                  value={dealer.id}
                                >
                                  {dealer.customer_name}{" "}
                                  {dealer.customer_number
                                    ? `(${dealer.customer_number.padStart(5, "0")})`
                                    : ""}
                                </MenuItem>
                              ))}
                          </Select>
                          {(props.meta.error || props.meta.submitError) &&
                            props.meta.touched && (
                              <Typography style={{ color: "red" }}>
                                {props.meta.error || props.meta.submitError}
                              </Typography>
                            )}
                        </FormControl>
                      </Grid>
                    )}
                  </Field>

                  <Field name="serial_number">
                    {(props) => (
                      <Grid item xs={3}>
                        <FormControl
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <HinField
                            id={props.input.name}
                            label={"Serial Number / HIN"}
                            placeholder="LYGAB123E456"
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                            helperText={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? props.meta.error || props.meta.submitError
                                : undefined
                            }
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>

                  <Field name="email">
                    {(props) => (
                      <Grid item xs={3}>
                        <FormControl
                          fullWidth
                          className={classes.fieldContainer}
                          error={
                            props.meta.error && props.meta.touched
                              ? !!props.meta.error
                              : undefined
                          }
                        >
                          <TextField
                            id={props.input.name}
                            label={"Email"}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            error={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? true
                                : false
                            }
                            helperText={
                              (props.meta.error || props.meta.submitError) &&
                              props.meta.touched
                                ? props.meta.error || props.meta.submitError
                                : undefined
                            }
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      disabled={isLoading}
                    >
                      Find
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<CloudDownloadIcon />}
                      disabled={isLoading}
                      style={{ marginLeft: 12 }}
                      size="small"
                      onClick={() => {
                        console.log("download", values);
                        downloadCustomers(customersMeta.page, values);
                      }}
                    >
                      Download
                    </Button>
                    <Button
                      variant="contained"
                      disabled={isLoading}
                      style={{ marginLeft: 12 }}
                      size="small"
                      onClick={() => {
                        setSelectedDealer(undefined);
                        setFilter(undefined);
                        loadCustomers(undefined, undefined);
                        form.change("dealer", undefined);
                        form.change("serial_number", "");
                        form.change("email", "");
                        form.change("first_name", "");
                        form.change("last_name", "");
                        form.change("city", "");
                        form.change("state", "");
                        form.change("search", "");
                      }}
                    >
                      Clear
                    </Button>
                    {isLoading && (
                      <CircularProgress size={20} style={{ marginLeft: 10 }} />
                    )}
                  </Grid>
                </Grid>

                {!pristine && Object.keys(errors as any).length > 0 && (
                  <Grid item xs={12}>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <Alert severity="error">
                        Please make sure all required fields are correctly
                        filled!
                      </Alert>
                    </div>
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        />
      </Paper>
      <TableContainer component={Paper} className={classes.container}>
        <Table size="small">
          <TableHead>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={8} style={{ padding: 0 }}>
                  <LinearProgress
                    id="customer-list-loading"
                    style={{ width: "100%" }}
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <SortButton
                  sortKey="boat__serial_number"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "left" }}
                >
                  Serial Number / HIN
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="first_name"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Name
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="boat__model"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Model
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="dealer_name"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Dealer
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="phone"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Phone
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="email"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Email
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="boat__purchase_date"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Purchase Date
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="boat__warranty_transfer"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Warranty Transfer
                </SortButton>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer, i) => {
              return (
                <CustomerRow
                  key={`warranty-customer-item-${customer.id}`}
                  customer={customer}
                />
              );
            })}
            {customers.length === 0 && (
              <TableRow>
                <TableCell colSpan={8}>
                  {isLoading ? "Loading..." : "No data yet."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={1}>
                {isLoading && <CircularProgress size={20} />}
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={8}
                count={customersMeta.count}
                rowsPerPage={customersMeta.page_size}
                page={customersMeta.page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={(event: any, newPage: number) => {
                  if (!isLoading)
                    loadCustomers(newPage + 1, selectedDealer, filter);
                }}
                onChangeRowsPerPage={(event: any) => {
                  if (!isLoading) setPageSize(parseInt(event.target.value, 10));
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

function CustomerRow(props: { customer: Customer }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [showCreateClaimForm, setShowCreateClaimForm] = useState(false);
  const [redirect, setRedirect] = useState("");
  const customer = props.customer;

  return (
    <TableRow>
      <TableCell>
        <Button
          component={RouterLink}
          to={`${appConfig.homepage}warranty/customers/${customer.id}/`}
          size="medium"
          style={{ textTransform: "none", padding: 0 }}
        >
          {customer.boat.serial_number}
        </Button>
      </TableCell>
      <TableCell align="right">
        <Button
          component={RouterLink}
          to={`${appConfig.homepage}warranty/customers/${customer.id}/`}
          size="medium"
          style={{ textTransform: "none", padding: 0 }}
        >
          {customer.first_name} {customer.last_name}
        </Button>
      </TableCell>
      <TableCell align="right">
        <Button
          component={RouterLink}
          to={`${appConfig.homepage}warranty/customers/${customer.id}/`}
          size="medium"
          style={{ textTransform: "none", padding: 0 }}
        >
          {customer.boat.model}
        </Button>
      </TableCell>
      <TableCell align="right">
        <Button
          component={RouterLink}
          to={`${appConfig.homepage}warranty/customers/${customer.id}/`}
          size="medium"
          style={{ textTransform: "none", padding: 0 }}
        >
          {customer.dealer?.customer_name}
        </Button>
      </TableCell>
      <TableCell align="right">
        <Button
          component={RouterLink}
          to={`${appConfig.homepage}warranty/customers/${customer.id}/`}
          size="medium"
          style={{ textTransform: "none", padding: 0 }}
        >
          {customer.phone ? customer.phone : "-"}
        </Button>
      </TableCell>
      <TableCell align="right">
        <Button
          component={RouterLink}
          to={`${appConfig.homepage}warranty/customers/${customer.id}/`}
          size="medium"
          style={{ textTransform: "none", padding: 0 }}
        >
          {customer.email ? customer.email : "-"}
        </Button>
      </TableCell>
      <TableCell align="right">
        <Button
          component={RouterLink}
          to={`${appConfig.homepage}warranty/customers/${customer.id}/`}
          size="medium"
          style={{ textTransform: "none", padding: 0 }}
        >
          {customer.boat.purchase_date
            ? moment(customer.boat.purchase_date).format("MMMM Do YYYY")
            : "-"}
        </Button>
      </TableCell>
      <TableCell align="right">
        <Button
          component={RouterLink}
          to={`${appConfig.homepage}warranty/customers/${customer.id}/`}
          size="medium"
          style={{ textTransform: "none", padding: 0 }}
        >
          {customer.boat.warranty_transfer ? "Yes" : "No"}
        </Button>
      </TableCell>
      <TableCell>
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => setShowCreateClaimForm(true)}>
            Enter Claim
          </MenuItem>
        </Menu>

        {!!redirect && <Redirect to={redirect} />}

        <Dialog
          open={showCreateClaimForm}
          onClose={() => {
            setShowCreateClaimForm(false);
          }}
          fullScreen
          TransitionComponent={Transition}
        >
          <>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" className={classes.dialogTitle}>
                  Create New Warranty Claim
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    setShowCreateClaimForm(false);
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <WarrantyClaimForm
                claim={null}
                dealerId=""
                customer={customer}
                onSave={(claim) => {
                  setShowCreateClaimForm(false);
                  setRedirect(`/warranty/claims/${claim.id}/`);
                }}
                onCancel={() => {
                  setShowCreateClaimForm(false);
                }}
              />
            </DialogContent>
          </>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}

function TablePaginationActions(props: {
  count: number;
  onChangePage?: (event: any, newPage: number) => void;
  page: number;
  rowsPerPage: number;
}) {
  const classes = useStyles();
  const { count, page, rowsPerPage } = props;

  const onChangePage = (event: any, newPage: number) => {
    if (props.onChangePage) props.onChangePage(event, newPage);
  };

  let numPages = Math.ceil(props.count / props.rowsPerPage);
  let paginations = Array.from({ length: numPages }, (_, i) => i + 1);
  if (paginations.length > 10) {
    let page = props.page + 1;
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(paginations.length - 3, paginations.length);
    let middles: number[] = [];
    if (starts.length && ends.length && page > 1) {
      if (!starts.includes(page - 1) && !ends.includes(page - 1))
        middles.push(page - 1);
      if (!starts.includes(page) && !ends.includes(page)) middles.push(page);
      if (
        page + 1 < numPages &&
        !starts.includes(page + 1) &&
        !ends.includes(page + 1)
      )
        middles.push(page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }

  return (
    <div className={classes.paginationControl}>
      <IconButton
        onClick={(event) => {
          onChangePage(event, 0);
        }}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={(event) => {
          onChangePage(event, page - 1);
        }}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {paginations.map((i) => (
        <Button
          key={`pagination-button-${i}`}
          disabled={i - 1 === props.page}
          onClick={() => {
            onChangePage(undefined, i - 1);
          }}
        >
          {i}
        </Button>
      ))}
      <IconButton
        onClick={(event) => {
          onChangePage(event, page + 1);
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(event) => {
          onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}
